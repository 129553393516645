import React, { useState, useEffect, useCallback } from 'react';
import Button from 'react-bootstrap/Button';
import { Link } from "react-router-dom";
import UAXN from '../images/favicon.png'
import axios from 'axios';
import PowerSettingsNewIcon from '@mui/icons-material/Bolt';
import WhiteLoader from '../images/LOADER.gif';
import Loader from '../images/loader3.gif';
import Reward from '@mui/icons-material/EmojiEvents';
import Tooltip from '@mui/material/Tooltip';
import InfoIcon from '@mui/icons-material/Info';

const inputContainerStyle = {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#211f24',
    borderRadius: '5px',
    padding: '6px 16px'
};

const currencyStyle = {
    marginLeft: '8px',
    color: '#fff',
    display: 'flex',
    alignItems: 'center',
    borderLeft: '2px solid #38363a'
};

const amountInputStyle = {
    ...inputContainerStyle,
    justifyContent: 'space-between'
};

const Stake = () => {
    const [email, setEmail] = useState('');
    const [token, setToken] = useState('');
    const [balanceAndPower, setBalanceAndPower] = useState('');
    const [loader, setLoader] = useState(true);
    const [walletAddress, setWalletAddress] = useState('');
    // const [amount, setAmount] = useState(0);
    // const [powerEquivalentToOneCoin, setPowerEquivalentToOneCoin] = useState(0);
    const [powerPerTxn, setPowerPerTxn] = useState('');
    const [msg, setMsg] = useState('');
    const [stakedAmtState, setStakedAmtState] = useState('');
    const [stakedDevices, setstakedDevices] = useState(0);
    const [earnedAmtState, setEarnedAmtState] = useState(0);
    const [generatedPower, setGeneratedPower] = useState(0);
    const [reserved_power, setreserved_power] = React.useState(0);
    const [reserved_balance, setreserved_balance] = React.useState(0);

    const [showValidatorKey, setshowValidatorKey] = useState([]);
    const [validator_loader, setvalidator_loader] = useState(true);
    const [software_unique_key, setsoftware_unique_key] = useState('');
    const [registered_uuid, setregistered_uuid] = useState('');
    const [stake_amount, setstake_amount] = useState(0);
    const [coinPrice, setCoinPrice] = useState(0);
    

    const fetchMachineID = async (validatorKey) => {
        try {
          const response = await axios.post(`https://webservices.uax.capital/get_my_machine_id`,{validator_key:validatorKey});
          return response.data.message;
        } catch (error) {
          console.error('Error fetching machine ID:', error);
          return 'Error';
        }
    };

    const fetchallValidatorKeys = async() =>{
        var token = localStorage.getItem("token")
        var email = localStorage.getItem("email")
        const config = {
          headers: { Authorization: `Bearer ${token}` }
        };
        const fetch_validator_key = await axios.post("https://services.uax.capital/api/fetch_validator_key",{
          email:email
        },config)
        if(fetch_validator_key.data.success){
          var keys = []
          if(fetch_validator_key.data.validator_keys.length>0){
            for(let i=0;i<fetch_validator_key.data.validator_keys.length;i++){
              const machine_id = await fetchMachineID(fetch_validator_key.data.validator_keys[i])
              // console.log(fetch_validator_key.data.validator_keys[i])
              keys.push({machine_id:machine_id,validator_key:fetch_validator_key.data.validator_keys[i]})
              setshowValidatorKey(keys)
              if(i==fetch_validator_key.data.validator_keys.length){
                setvalidator_loader(false)
              }
            }
          }
          setvalidator_loader(false)
        }
        else{
          setshowValidatorKey([])
          setvalidator_loader(false)
        }
    }

    const StakeNow = async () => {
        setMsg(<img src={Loader} style={{ width: "3vw" }} />);
        if (parseFloat(stake_amount) >= (500/parseFloat(coinPrice)).toFixed(2)) {
            if(software_unique_key.length>0){
                if(registered_uuid.length>0){
                    if(walletAddress.length>0){
                        try {
                            const config = { headers: { Authorization: `Bearer ${token}` } };
                            const stakeResp = await axios.post("https://services.uax.capital/api/stakeNow", {
                                software_unique_key: software_unique_key,
                                address:walletAddress,
                                registered_uuid:registered_uuid,
                                stake_amount:stake_amount,
                                email
                            }, config);
                            setMsg(stakeResp.data.message)
                        } catch (error) {
                            setMsg("An error occurred, please try again later");
                        }
                    }
                    else{
                        setMsg("Unable to fetch wallet address");
                    }
                }
                else{
                    setMsg("Invalid machine ID");
                }
            }
            else{
                setMsg("Invalid validator key");
            }
        } else {
            setMsg(`Stake amount must be greater than or equal to ${(500/parseFloat(coinPrice)).toFixed(2)} UAXN`);
        }
    };

    const fetchData = useCallback(async () => {
        try {
            const token = localStorage.getItem("token");
            const email = localStorage.getItem("email");
            if (token && email) {
                setEmail(email);
                setToken(token);
                const config = { headers: { Authorization: `Bearer ${token}` } };
                
                const [
                    verifyTokenResponse,
                    priceResponse,
                    balanceAndPowerResponse,
                    walletResponse,
                    powerConsumptionResponse,
                    combined_wallet_response,
                    getSummary,
                ] = await Promise.all([
                    axios.post('https://services.uax.capital/api/verifyToken', { token, email }),
                    axios.get("https://cmw.uax.network/get_current_price"),
                    axios.post("https://services.uax.capital/api/getUserBalanceAndPower", { email }, config),
                    axios.post('https://services.uax.capital/api/getUserWallet', { email }, config),
                    axios.get("https://cmw.uax.network/estimate_power"),
                    axios.post("https://webservices.uax.capital/get_combined_wallet_info", { wallet_address: walletAddress }),
                    axios.post("https://services.uax.capital/api/getNFTsAndOffersSummary",{
                        email
                      },config)
                ]);

                if (verifyTokenResponse.data === "Token Expired") {
                    localStorage.clear();
                    window.location.href = '/login';
                } else {
                    setCoinPrice(priceResponse.data.current_price);
                    setBalanceAndPower(balanceAndPowerResponse.data);
                    setreserved_power((parseFloat(getSummary.data.totalAskAmounts)*212)+(parseFloat(getSummary.data.totalNFTsListedForSell)*212))
                    setreserved_balance((parseFloat(getSummary.data.totalBidAmount)))
                    setWalletAddress(walletResponse.data);
                    setPowerPerTxn(powerConsumptionResponse.data);
                    setStakedAmtState(isNaN(combined_wallet_response.data.stakedAmount) ? 0 : combined_wallet_response.data.stakedAmount);
                    setEarnedAmtState(isNaN(combined_wallet_response.data.earningsFromStake) ? 0 : combined_wallet_response.data.earningsFromStake);
                    setGeneratedPower(isNaN(combined_wallet_response.data.generatedPower) ? 0 : combined_wallet_response.data.generatedPower);
                    setstakedDevices(isNaN(combined_wallet_response.data.totalDevices) ? 0 : combined_wallet_response.data.totalDevices);
                    setLoader(false);
                }
            } else {
                window.location.href = "/login";
            }
        } catch (error) {
            console.error("An error occurred while fetching data", error);
        }
    }, [walletAddress]);

    useEffect(() => {
        fetchData();
        fetchallValidatorKeys();
    }, [fetchData]);
    return (
        <>
            <div className="container" style={{ minHeight: "100vh" }}>
                <div className='mt-3'>
                    <div className='dashboard_box_001____ px-4 mb-4'>
                        <div className='my-5 for_device_difference____mx_5____'>
                            <p style={{ fontWeight: "900", fontSize: "20px" }}>Staking Information</p>
                            <div className='mt-4'>
                                <div className='row'>
                                    <div className='col-lg-3 col-md-6 col-sm-6 mt-2'>
                                        <div className="section_balance_and_stake____">
                                            <span style={{ fontWeight: "500" }}>Balance</span><br />
                                            <img src={UAXN} style={{ width: "18px" }} />
                                            <span style={{ color: "#0ce456", fontSize: "18px", marginLeft: "10px", fontWeight: "900" }}>
                                                {loader ? <img src={WhiteLoader} style={{ width: "2vw" }} /> : `${(parseFloat(balanceAndPower.balance)-parseFloat(reserved_balance)).toFixed(3)} UAXN`}
                                            </span>
                                        </div>
                                    </div>
                                    <div className='col-lg-3 col-md-6 col-sm-6 mt-2'>
                                        <div className="section_balance_and_stake_brown____">
                                            <span style={{ fontWeight: "500" }}>Staked / Validators</span><br />
                                            <img src={UAXN} style={{ width: "18px" }} />
                                            <span style={{ color: "#f99f1b", fontSize: "18px", marginLeft: "10px", fontWeight: "900" }}>
                                                {loader ? <img src={WhiteLoader} style={{ width: "2vw" }} /> : `${stakedAmtState || 0} / ${stakedDevices || 0}`}
                                            </span>
                                        </div>
                                    </div>
                                    <div className='col-lg-3 col-md-6 col-sm-6 mt-2'>
                                        <div className="section_balance_and_stake_white____">
                                            <span style={{ fontWeight: "500" }}>Generated Power</span><br />
                                            <PowerSettingsNewIcon style={{ color: "#fff" }} />
                                            <span style={{ color: "#fff", fontSize: "18px", marginLeft: "5px", fontWeight: "900" }}>
                                                {loader ? <img src={WhiteLoader} style={{ width: "2vw" }} /> : `${parseFloat(generatedPower).toFixed(2)}`}
                                            </span>
                                        </div>
                                    </div>
                                    <div className='col-lg-3 col-md-6 col-sm-6 mt-2'>
                                        <div className="section_balance_and_stake_blue____">
                                            <span style={{ fontWeight: "500" }}>Stake Rewards</span><br />
                                            <Reward style={{ color: "#fff" }} />
                                            <span style={{ color: "#447be1", fontSize: "18px", marginLeft: "5px", fontWeight: "900" }}>
                                                {loader ? <img src={WhiteLoader} style={{ width: "2vw" }} /> : `${parseFloat(earnedAmtState).toFixed(2)} UAXN`}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='dashboard_box_001____ px-4'>
                        <div className='my-5 for_device_difference____mx_5____'>
                            <span style={{ fontWeight: "900", fontSize: "20px" }}>Need reward? Stake Now!</span>
                            <div className='mt-5'>
                                <div className="section_resources_____">
                                    {/* <label className="label">
                                        <Tooltip title="Power is essential in UAX blockchain for processing transactions and ensuring network security.">
                                            <InfoIcon style={{ color: "#863593", marginRight: "5px" }} />
                                        </Tooltip>
                                        Power
                                        <span className="horizontal-line"></span>
                                        <span style={{ float: "right" }}>
                                            {loader ? <img src={WhiteLoader} style={{ width: "2vw" }} /> : `${powerEquivalentToOneCoin * (amount || 0).toFixed(2)}`}
                                        </span>
                                    </label> */}
                                    <small style={{ marginLeft: "20px", color: "#fff",fontSize:"17px" }}>
                                        {/* {powerPerTxn} */}
                                        Estimated Fees : 424 POWER
                                    </small>
                                    <br />
                                    <br />
                                    <small style={{ marginLeft: "20px", color: "#fff",fontSize:"17px" }}>
                                        Available Power
                                        <span style={{ float: "right", color: "#fff",fontSize:"17px" }}>{(parseFloat(balanceAndPower.power)-parseFloat(reserved_power)).toFixed(2)}</span>
                                    </small>
                                </div>
                                <div className='mt-5'>
                                    <p style={{ fontWeight: "600" }}>Get resources Power with UAXN</p>
                                    <div className="row">
                                        <div className="col-lg-4 col-md-12 col-sm-12 mt-2">
                                            <div style={{ display: 'block', alignItems: 'center', backgroundColor: '#211f24', borderRadius: '5px', padding: '6px 16px' }}>
                                                <select
                                                    className="w-100"
                                                    style={{ color: "#fff", backgroundColor: "transparent",border:"none" }}
                                                      onChange={(e) => {
                                                        const selectedKey = e.target.value;
                                                        setsoftware_unique_key(selectedKey); 
                                                        setMsg('')
                                                      }}
                                                    >
                                                    <option value="" disabled selected>
                                                        Select Validator Key
                                                    </option>
                                                    {showValidatorKey.map((index) => (
                                                        <option key={index.validator_key} value={index.machine_id === "Key not found" || 'Error' ? index.validator_key : ''}>
                                                            {index.machine_id === "Key not found" || 'Error' ? index.validator_key : ''}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-12 col-sm-12 mt-2">
                                            <div style={{ display: 'block', alignItems: 'center', backgroundColor: '#211f24', borderRadius: '5px', padding: '6px 16px' }}>
                                            <input
                                                    type="text"
                                                    onChange={(e)=>{ 
                                                        setregistered_uuid(e.target.value);
                                                        setMsg('') }}
                                                    placeholder='Enter Machine ID'
                                                    style={{ backgroundColor: 'transparent', border: 'none', color: 'white', flex: 1 }}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-12 col-sm-12 mt-2">
                                            <div style={{ display: 'block', alignItems: 'center', backgroundColor: '#211f24', borderRadius: '5px', padding: '6px 16px' }}>
                                                <input
                                                    type="number"
                                                    onChange={(e)=>{
                                                        setstake_amount(e.target.value);
                                                        setMsg('')}}
                                                    placeholder='Enter Amount to Stake'
                                                    style={{ backgroundColor: 'transparent', border: 'none', color: 'white', flex: 1 }}
                                                />
                                            </div>
                                        </div>
                                        <div className="small-box mt-4 text-center">
                                            <Button
                                                className="primary_btnn___"
                                                variant="primary"
                                                onClick={StakeNow}
                                            >
                                                Stake Now!
                                            </Button>
                                        </div>
                                    </div>
                                    <center>
                                        {msg && <div className="alert alert-success mt-3" role="alert">{msg}</div>}
                                    </center>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Stake;
